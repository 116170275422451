<template>
  <MainPage />
</template>

<script>
import MainPage from "./pages/MainPage.vue";
export default {
  components: { MainPage },
};
</script>
